






















































import KTextInput from '@/@core/components/input/KTextInput.vue'
import KText from '@/@core/components/typography/KText.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { required, minLength, equalWith } from "@/@core/helpers/inputValidator"
import KPasswordInput from '@/@core/components/input/KPasswordInput.vue'

export default defineComponent({
    components: { KTextInput, KText, KPasswordInput },
    name: 'RegisterForm',
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const isValid = ref(false)
        const data = ref({
            username: '',
            password: '',
            reTypePassword: '',
        })

        const onSubmit = () => emit('submit', data.value)

        return {
            data,
            onSubmit,
            required,
            minLength,
            equalWith,
            isValid
        }
    }
})
